<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    full-buttons
    submit-title="Yes"
    title="Confirm updates?"
    width="400"
    v-on="$listeners"
    @close="close"
    @submit="confirm"
  >
    <div class="mb-4 break-word">
      Changing of the scoring profile has triggered recalculation for addresses that have been added to the Monitoring. Confirm action?
    </div>
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'

export default {
  components: {
    GlModal,
  },
  inheritAttrs: false,
  props: {
    id: {
      type: [Number, String],
      require: true,
      default: null,
    },
  },
  methods: {
    close() {
      console.log(1)
      this.$emit('close', this.id)
    },
    confirm() {
      this.$emit('confirm', this.id)
    },
  }
}
</script>
