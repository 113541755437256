<template>
  <div
    class="pp-scoring-profile__card"
    @click.stop="$emit('select', id)"
  >
    <div class="pp-scoring-profile__card-top">
      <div
        v-if="!isEdit"
        class="pp-scoring-profile__card-header"
      >
        {{ profileName }}
      </div>
      <GlInput
        v-else
        v-model="localName"
        is-light
      />
      <div
        v-if="id"
        class="pp-scoring-profile__card-context"
        @click.stop="openContextMenu"
      >
        <gl-icon
          class="pp-scoring-profile__card-context-icon"
          :height="20"
          name="pp-context"
          :width="20"
        />
      </div>
    </div>
    <div class="pp-scoring-profile__card-bottom">
      <div
        class="pp-scoring-profile__card-status"
        :class="{ 'pp-scoring-profile__card-status--active' : activeStatus }"
      >
        {{ activeStatus ? 'Active' : 'Inactive' }}
      </div>
      <div class="pp-scoring-profile__card-switch">
        <o-switch
          v-model="active"
          :disabled="active"
          @input="$emit('update', id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
// Components
import GlIcon from "@/components/gl-icon.vue";
import GlInput from "@/components/gl-input.vue";
export default {
  components: {
    GlIcon,
    GlInput,
  },
  props: {
    profileName: {
      type: String,
      default: 'Default Profile'
    },
    activeStatus: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: [Number, String],
      require: true,
      default: null
    },
  },
  data() {
    return {
      active: false,
      localName: '',
      contextMenuPosition: { x: 0, y: 0 },
    }
  },
  watch: {
    activeStatus: {
      handler(to) {
        this.active = to
      },
      immediate: true
    },
    localName: {
      handler(to) {
        this.$emit('rename', to)
      },
      immediate: true
    }
  },
  methods: {
    openContextMenu(e) {
      const { x, y } = e
      this.contextMenuPosition = { x, y }
      this.$emit('ctx-open', { position: this.contextMenuPosition, id: this.id })
    }
  },
}
</script>

<style scoped>
.pp-scoring-profile__card {
  min-width: 213px;
  max-width: 330px;
  padding: 17px 16px 16px 16px;
  background-color: var(--cotton-grey-f-5);
  border: 1px solid var(--cotton-grey-f-5);
  border-radius: 8px;
  transition: background-color 0.3s ease, border 0.3s ease;
}
.pp-scoring-profile__card--active {
  background-color: var(--profile-card-background);
  border: 1px solid var(--soft-blue);
}
.pp-scoring-profile__card-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 16px;
}
.pp-scoring-profile__card-header {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.pp-scoring-profile__card-context {
  display: flex;
  align-items: center;
  height: 17px;
  cursor: pointer;
}
.pp-scoring-profile__card-context svg {
  display: block;
  min-width: 20px;
}

.pp-scoring-profile__card-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pp-scoring-profile__card-switch {
  display: flex;
  align-items: center;
}
.pp-scoring-profile__card-switch::v-deep .o-switch__label {
  display: none;
}
.pp-scoring-profile__card-status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 91px;
  height: 20px;
  background-color: var(--dark-grey-d-3);
  border-radius: 3px;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
  color: var(--white);
  transition: background-color 0.3s ease;
}
.pp-scoring-profile__card-status--active {
  width: 78px;
  background-color: var(--soft-blue);
}
</style>
