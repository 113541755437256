<template>
  <div
    v-if="value"
    v-on-clickaway="closeMenu"
    class="gl-context-menu"
    oncontextmenu="return false"
    :style="contextStyle"
  >
    <gl-menu-item
      class="mb-1"
      icon="edit-action"
      :icon-height="24"
      :icon-width="24"
      label="Edit"
      @click="editActivate"
    />
    <gl-menu-item
      icon="delete"
      :icon-height="24"
      :icon-width="24"
      label="Delete"
      warn
      @click="deleteActivate"
    />
  </div>
</template>

<script>
// Libs
import { mixin as clickaway } from 'vue-clickaway'
// Components
import GlMenuItem from '@/components/gl-menu-item'

export default {
  components: {
    GlMenuItem,
  },
  mixins: [clickaway],
  props: {
    position: {
      type: Object,
      default: () => ({ x: 0, y: 0 }),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      contextStyle: {
        left: `${ this.position.x }px`,
        top: `${ this.position.y }px`,
      },
    }
  },
  watch: {
    position: {
      immediate: true,
      deep: true,
      handler() {
        this.$nextTick(() => {
          const menu = this.$el
          const { x, y } = this.position
          const availableWidth = menu.parentNode.clientWidth - x
          const availableHeight = menu.parentNode.clientHeight - y
          this.contextStyle = {
            left: `${ menu.clientWidth > availableWidth ? x - menu.clientWidth : x }px`,
            top: `${ menu.clientHeight > availableHeight ? y - menu.clientHeight : y }px`,
          }
        })
      },
    },
  },
  methods: {
    closeMenu() {
      this.$emit('input', false)
    },
    editActivate() {
      this.$emit('edit-activate', this.data)
    },
    deleteActivate() {
      this.$emit('delete-activate', this.data)
    },
  },
}
</script>
