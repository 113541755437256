<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    :side-valid="sideValid"
    submit-title="Edit"
    title="Edit profile"
    width="400"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="onSubmit"
  >
    <gl-input
      v-model="localData.name"
      class="gl-modal__row"
      :height="40"
      label="Profile Name"
      name="profileName"
      rules="required"
      vid="name"
    />
  </gl-modal>
</template>

<script>
// Components
import GlInput from '@/components/gl-input'
import GlModal from '@/components/gl-modal'

export default {
  components: {
    GlModal,
    GlInput,
  },
  inheritAttrs: false,
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      localData: null,
    }
  },
  computed: {
    sideValid() {
      return this.localData?.name?.length === 0
    },
  },
  mounted() {
    this.localData = JSON.parse(JSON.stringify(this.data))
  },
  methods: {
    onSubmit() {
      this.$emit('edit', this.localData)
    },
  },
}
</script>

<style>
.users-date-picker .mx-input {
  height: 40px !important;
}
</style>
