<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    :loading="loading"
    submit-title="Ok"
    title="Confirm updates?"
    width="400"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="confirm"
  >
    <div class="mb-4 break-word">
      {{ message }}
    </div>
  </gl-modal>
</template>

<script>
import GlModal from '@/components/gl-modal'

export default {
  components: {
    GlModal,
  },
  inheritAttrs: false,
  props: {
    message: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm')
    },
  }
}
</script>
