<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    closable
    submit-title="Reset"
    title="Confirm Action"
    width="400"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="$emit('confirm', name)"
  >
    <div class="mb-4">
      Are you sure you want to reset score to the default value for type <b>{{ name }}</b>?
    </div>
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'

export default {
  name: 'ResetModal',
  components: {
    GlModal,
  },
  inheritAttrs: false,
  props: {
    name :{
      type: String,
      default: ''
    },
  }
}
</script>
