<template>
  <GlPageWrap
    class="pp-page__scoring-profile"
    hide-title-on-mobile
    title="Scoring Profiles"
  >
    <template #actions>
      <div class="pp-page__scoring-profile-actions">
        <GlCoinSelect
          v-if="!config.VUE_APP_MULTI_LABELING"
          @change="loadData"
        />
        <GlButton
          class="m-fullwidth"
          dark
          :disabled="profilesLoading"
          title="Create Profile"
          @click="handleCreateProfile"
        >
          <template #beforeTitle>
            <gl-icon
              class="mr-2"
              height="20"
              name="pp-add"
              width="20"
            />
          </template>
        </GlButton>
      </div>
    </template>
    <div class="pp-scoring-profile__header">
      <div
        v-if="profilesLoading"
        class="full-width flex justify-center"
      >
        <GlLoader />
      </div>
      <div
        v-else
        class="pp-scoring-profile__header-wrap"
      >
        <ProfileCard
          v-for="(profile, index) in profilesList"
          :id="profile.id"
          :key="profile.id"
          ref="profileCard"
          :active-status="profile.active"
          :class="{ 'pp-scoring-profile__card--active' : selectedProfileId === profile.id }"
          :is-edit="profile.isEdit"
          :profile-name="profile.name"
          @ctx-open="ctxOpenHandler"
          @select="selectProfile($event, index)"
          @update="updateActiveStateProfile($event)"
        />
        <ContextMenu
          ref="ctx"
          v-model="showContextMenu"
          :data="ctxProfileData"
          :position="contextMenuPosition"
          @delete-activate="deleteActivate"
          @edit-activate="editActivate"
        />
      </div>
    </div>
    <div class="pp-scoring-profile__content">
      <TypesScoring
        v-if="selectedProfileId !== undefined && !profilesLoading"
        :can-edit-score="Boolean(selectedProfileId)"
        :can-reset-score="Boolean(selectedProfileId)"
        :profile-id="selectedProfileId"
        with-def-score
        @loading="loading = $event"
      />
      <TagsScoring
        v-if="selectedProfileId !== undefined && !profilesLoading"
        :can-edit-score="Boolean(selectedProfileId)"
        :can-reset-score="Boolean(selectedProfileId)"
        :profile-id="selectedProfileId"
        with-def-score
        @loading="loading = $event"
      />
    </div>
    <CreateProfileModal
      v-if="creatingProfile"
      v-model="creatingProfile"
      :loading="loading"
      @add="handleSaveProfile"
      @close="creatingProfile = false"
    />
    <EditProfileModal
      v-if="editingProfile"
      v-model="editingProfile"
      :data="ctxProfileData"
      @close="editingProfile = false"
      @edit="handleEditProfile"
    />
    <deleteProfileModal
      v-if="deletedProfile"
      v-model="deletedProfile"
      :data="ctxProfileData"
      @close="deletedProfile = false"
      @delete="handleDeleteProfile"
    />
    <ConfirmUpdateCalcModal
      v-if="confirmRecalc"
      :id="preSelectedProfileId"
      v-model="confirmRecalc"
      @close="handleCloseConfirmRecalcModal"
      @confirm="handleRecalcProfile"
    />
  </GlPageWrap>
</template>

<script>
// Vuex
import { mapActions, mapState } from "vuex";
// Components
import GlLoader from "@/components/gl-loader.vue";
import GlButton from "@/components/gl-button";
import GlIcon from '@/components/gl-icon';
import GlCoinSelect from "@/components/gl-coin-select.vue";
import GlPageWrap from "@/components/layout/gl-page-wrap.vue";
import ContextMenu from "@/pages/scoring-profiles/components/ContextMenu.vue";
import ProfileCard from "@/pages/scoring-profiles/components/ProfileCard.vue";
import TagsScoring from "@/pages/scoring-profiles/components/TagsScoring.vue";
import TypesScoring from "@/pages/scoring-profiles/components/TypesScoring.vue";
import EditProfileModal from "@/pages/scoring-profiles/modals/editProfileModal.vue";
import deleteProfileModal from "@/pages/scoring-profiles/modals/deleteProfileModal.vue";
import CreateProfileModal from "@/pages/scoring-profiles/modals/createProfileModal.vue";
// Configs
import config from "@/utils/appConfig";
import ConfirmUpdateCalcModal from "@/pages/scoring-profiles/modals/confirmUpdateCalcModal.vue";

export default {
  components: {
    GlLoader,
    GlPageWrap,
    ContextMenu,
    ProfileCard,
    TagsScoring,
    GlCoinSelect,
    TypesScoring,
    EditProfileModal,
    CreateProfileModal,
    deleteProfileModal,
    ConfirmUpdateCalcModal,
    GlButton,
    GlIcon,
  },
  data() {
    return {
      selectedProfileId: undefined,
      preSelectedProfileId: undefined,
      ctxProfileData: null,
      loading: false,
      profilesLoading: false,
      creatingProfile: false,
      editingProfile: false,
      deletedProfile: false,
      showContextMenu: false,
      confirmRecalc: false,
      contextMenuPosition: { x: 0, y: 0 },
      profilesList: []
    }
  },
  computed: {
    ...mapState('user', ['userData']),
    config() {
      return config
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    ...mapActions('scoringProfiles', ['getProfilesList', 'createProfile', 'activateProfile', 'deleteProfile', 'editProfile']),
    ...mapActions('user', ['getMe']),
    editActivate(val) {
      this.ctxProfileData = val
      this.editingProfile = true
    },
    deleteActivate(val) {
      this.ctxProfileData = val
      this.deletedProfile = true
    },
    async loadData() {
      this.getProfilesList().then(({ data, success }) => {
        if (success) {
          this.profilesList = data || []
          this.selectedProfileId = this.profilesList.find(pr => pr.active).id
        } else {
          this.profilesList = []
          this.selectedProfileId = null
        }
      }).finally(() => {
        this.profilesLoading = false
      })
    },
    ctxOpenHandler(val) {
      this.showContextMenu = false
      this.contextMenuPosition = val.position
      this.ctxProfileData = this.profilesList.find(pr => pr.id === val.id) || null
      this.showContextMenu = true
    },
    handleCreateProfile() {
      this.creatingProfile = true
    },
    handleSaveProfile(name) {
      this.loading = true
      this.createProfile(name).then(({ data, success }) => {
        if (success) {
          this.creatingProfile = false
          this.loadData()
        } else {
          this.$toasted.global.error({message: `${data.message}`})
        }
      }).catch(({ response: { data } }) => {
        this.loading = false
        this.$toasted.global.error({message: `${data.data.message}`})
      }).finally(() => {
        this.loading = false
      })
    },
    handleEditProfile(profileData) {
      this.editProfile({ body: profileData }).then(({ data, success }) => {
        if (success) {
          this.editingProfile = false
          this.loadData()
        } else {
          this.$toasted.global.error({message: `${data.message}`})
        }
      }).catch(({ response: { data } }) => {
        this.$toasted.global.error({message: `${data.data.message}`})
      })
    },
    handleDeleteProfile({ id }) {
      this.deleteProfile(id).then(({ data, success }) => {
        if (success) {
          this.deletedProfile = false
          this.loadData()
        } else {
          this.$toasted.global.error({message: `${data.message}`})
        }
      }).catch(({ response: { data } }) => {
        this.$toasted.global.error({message: `${data.data.message}`})
      })
    },
    selectProfile(id) {
      this.selectedProfileId = id
    },
    async handleCloseConfirmRecalcModal(id) {
      this.confirmRecalc = false

      const index = this.profilesList.findIndex(pr => pr.id === id)

      if (index !== -1) {
        this.profilesList.splice(index, 1, { ...this.profilesList[index], active: false })
        this.$refs.profileCard[index].active = false
      }
    },
    handleRecalcProfile() {
      this.confirmRecalc = false
      this.updateActiveStateProfile(this.selectedProfileId, true)
    },
    updateActiveStateProfile(id, recalculated = false) {
      this.confirmRecalc = true
      this.preSelectedProfileId = id

      if (recalculated) {
        this.profilesLoading = true
        this.activateProfile({ id }).then(({ success, data }) => {

          this.loadData()

          if (recalculated) this.confirmRecalc = false

          if (!success) {
            this.$toasted.global.error({message: `${data.message}`})
          }
        }).finally(() => {
          this.profilesLoading = false
        })
      }
    },
  },
}
</script>

<style>
.pp-page__scoring-profile .gl-button {
  text-transform: none;
}

.pp-page__scoring-profile-actions {
  display: flex;
  align-items: center;
  gap: 16px;
}
.pp-page__scoring-profile-actions .v-select.mr-2 {
  margin-right: 0 !important;
}
.pp-page__scoring-profile-actions .coin-select-base .vs__dropdown-toggle {
  min-height: 32px;
  padding-bottom: 0;
}

.pp-page__scoring-profile .pp-pagination-table {
  padding: 16px 0 0 0;
}

/* header */
.pp-scoring-profile__header {
  margin-bottom: 24px;
  padding: 24px;
  background-color: var(--white);
  border-radius: 12px;
}
.pp-scoring-profile__header-wrap {
  display: flex;
  gap: 24px;
  overflow-y: auto;
}
.pp-scoring-profile__header-wrap::-webkit-scrollbar {
  height: 6px;
}

.pp-scoring-profile__content {
  display: flex;
  gap: 24px;
}
.pp-scoring-profile__left,
.pp-scoring-profile__right {
  display: flex;
  flex-direction: column;
  max-width: calc(50% - 12px);
  min-width: calc(50% - 12px);
  padding: 24px 16px 16px 16px;
  background-color: var(--white);
  border-radius: 12px;
  position: relative;
}

/* content */
.pp-scoring-profile__content-header {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--space-cadet);
}
.pp-scoring-profile__search-wrap {
  display: flex;
  gap: 16px;
  align-items: flex-end;
  margin-bottom: 16px;
}
.pp-scoring-profile__search-wrap .gl-button {
  min-width: 105px;
  height: 32px;
  font-size: 16px;
}
.pp-scoring-profile__search-input {
  flex: 1 0 auto;
}
.pp-scoring-profile__search-input .mb-1 {
  margin-bottom: 0 !important;
}
.pp-scoring-profile__search-input .gl-input__label {
  margin-bottom: 8px;
  font-weight: 400;
  text-transform: none;
}
.pp-scoring-profile__search-input .gl-input__input {
  height: 32px !important;
  border-radius: 6px;
  font-size: 14px !important;
}
.pp-scoring-profile__content-wrap {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

/* PP Table */
.pp-table {
  flex: 1 0 auto;
  overflow: auto !important;
}
.pp-table::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.pp-table .o-table {
  table-layout: auto !important;
}
.pp-table .o-table__th__sort-icon {
  right: 8px;
}
.pp-table .chip {
  padding: 0 8px;
  font-size: 10px;
  line-height: 16px;
}
.pp-scoring-profile__table-name {
  font-size: 12px;
  line-height: 14px;
  white-space: nowrap;
}
.pp-scoring-profile__table-default-score {
  width: min-content;
}
.pp-scoring-profile__table-actions {
  display: flex;
  align-items: center;
  gap: 12px;
  max-width: 52px;
  min-width: 52px;
}
.pp-scoring-profile__table-actions .gl-icon {
  display: block;
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.pp-table .o-table__th {
  padding: 0 24px 12px 0 !important;
  border-bottom: 1px solid var(--cotton-grey-f-5) !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: var(--space-cadet) !important;
  text-transform: none !important;
  white-space: nowrap !important;
}
.pp-table .o-table__td {
  padding: 8px 24px 8px 0 !important;
  border-bottom: 1px solid var(--cotton-grey-f-5);
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: var(--space-cadet) !important;
  text-transform: none !important;
}
.pp-table .o-table__td:not(.o-table__td:first-child),
.pp-table .o-table__th:not(.o-table__th:first-child) {
  width: 0;
}

.pp-scoring-profile__loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  backdrop-filter: blur(4px);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1200px) {
  select, textarea, input {
    font-size: 14px !important;
  }
}
@media (max-width: 1200px) {
  .pp-scoring-profile__content {
    display: block;
  }
  .pp-scoring-profile__left, 
  .pp-scoring-profile__right {
    max-width: 100%;
    min-width: 100%;
  }
  .pp-scoring-profile__left + .pp-scoring-profile__right {
    margin-top: 24px;
  }
}

@media (max-width: 767px) {
  .pp-page__scoring-profile {
    padding-top: 56px;
  }
  .pp-page__scoring-profile-actions {
    flex-direction: column;
    min-width: 100%;
    padding-top: 10px;
  }
  .pp-page__scoring-profile-actions > div {
    min-width: 100%;
  }
  .pp-scoring-profile__header {
    margin-bottom: 10px;
    padding: 16px;
    border-radius: 0 0 12px 12px;
  }
  .pp-scoring-profile__left + .pp-scoring-profile__right {
    margin-top: 10px;
  }
}
</style>
