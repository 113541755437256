<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    closable
    submit-title="Delete"
    title="Delete profile"
    width="400"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="$emit('delete', data)"
  >
    <div class="mb-4">
      <div v-if="!data.active">
        You are going to delete profile <b>{{ data.name }}</b> action cannot be undone.
      </div>
      <div v-else>
        You are going to delete active profile <b>{{ data.name }}</b>. After deleting this type, the default profile will automatically be applied. This action cannot be undone.
      </div>
    </div>
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'

export default {
  name: 'DeleteTagsModal',
  components: {
    GlModal,
  },
  inheritAttrs: false,
  props: {
      data: {
        type: Object,
        default: () => ({})
      }
  },
}
</script>
