<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    :loading="loading"
    :side-valid="name.length === 0"
    submit-title="Create"
    title="Create profile"
    width="400"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="onSubmit"
  >
    <gl-input
      v-model="name"
      class="gl-modal__row"
      :height="40"
      label="Profile Name"
      name="profileName"
      rules="required"
      vid="name"
    />
  </gl-modal>
</template>

<script>
// Components
import GlInput from '@/components/gl-input'
import GlModal from '@/components/gl-modal'

export default {
  components: {
    GlModal,
    GlInput,
  },
  inheritAttrs: false,
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      name: '',
    }
  },
  methods: {
    onSubmit() {
      this.$emit('add', this.name)
    },
  },
}
</script>

<style>
.users-date-picker .mx-input {
  height: 40px !important;
}
</style>
