<template>
  <div
    v-if="spinGrayView"
    class="gl-loader-spin"
  >
    <img
      alt="loader"
      src="~@/assets/svg/loaders/loader-small-gray.svg"
      :style="`height: ${height}`"
    >
  </div>
  <div
    v-else-if="spinBlueView"
    class="gl-loader-spin"
  >
    <img
      alt="loader"
      src="@/assets/svg/loaders/loader-blue.svg"
      :style="`height: ${height}`"
    >
  </div>
  <div
    v-else
    class="gl-loader"
  >
    <img
      alt="loader"
      src="../../public/assets/img/Pre.gif"
      :style="`height: ${height}`"
    >
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#383c54',
    },
    height: {
      type: String,
      default: '50px',
    },
    spinGrayView: {
      type: Boolean,
      default: false,
    },
    spinBlueView: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
